@media (prefers-color-scheme: dark){
  .Splash-header {
    background-color: #393939;
    z-index: -2;
  }

  .Splash-logo {
    background-image: url(logoDefault.svg)!important;
  }
}

@media (prefers-color-scheme: light){
  .Splash-header {
    background-color: #fafafa;
    z-index: -2;
  }
  
  .Splash-logo {
    background-image: url(logoDark.svg)!important;
  }
}

/** Background for... */
/* .Splash-header::before{
  content: '';
  width: 100%;
  height: 500px;
  left: 0;
  right: 0;
  position: absolute;
  background-image: url(texture.jpg);
  background-position:center;
  background-size: contain;
  z-index: 0;

} */
@media (max-width: 933px ){
  .Splash-header::before{
    background-size: cover;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.virtual-list{
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.Splash-actions{
  z-index: 10000;
}

.Splash {
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Splash-logo {
  background-image: url(logoDark.svg);
  margin-bottom: 3rem;
  pointer-events: none;
  background-size: cover;
  -webkit-animation: light-pulse 5s linear infinite alternate;
  -moz-animation: light-pulse 5s linear infinite alternate;
  animation: light-pulse 5s linear infinite alternate;
  user-select: none;
}


@keyframes light-pulse {
  0%   { filter: drop-shadow(0 0 0.15rem crimson ) }
  25%   { filter: drop-shadow(0 0 0.5rem orange) }
  50% { filter: drop-shadow(0 0 1rem gold) }
  75%   { filter: drop-shadow(0 0 0.5rem orange) }
  100% { filter: drop-shadow(0 0 1rem crimson) }
}


.Splash-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #999;
}

.Splash-link {
  color: #61dafb;
}

@media(min-device-width: 933px){ 
  ::-webkit-scrollbar {
    width: 7px;
    height: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #cccccc;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #777777;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #393939;
  }
  ::-webkit-resizer,
  ::-webkit-scrollbar-button,
  ::-webkit-scrollbar-corner { display: none; }
}