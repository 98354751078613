#root{
  overflow: hidden;
}

.MuiAppBarBottom {
  top: auto;
  bottom: 0;
}

.MuiGrid-root.MuiGrid-container {
  height: 100%;
}

/*mobile root*/
@media(max-device-width: 933px){
  div[data-swipeable] {
    min-height: 100vh
  }
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

clickable{
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  place-content: space-between;
  user-select: none;
}

.body-enter-done{
  position: relative;
  overflow: hidden;
  height: 100%;
}

.body-enter {
  opacity: 0;
  z-index: 1;
  overflow: hidden;
  position: fixed;
  left: 0; right: 0;top: 0; bottom: 0
}

.body-enter.body-enter-active {
  opacity: 1;
  transition: opacity 155ms ease-in;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiInput-root input .MuiFormControl-root  {
  background-color: aliceblue;
}

.image-gallery-content.fullscreen .image-gallery-slides{
  height: calc(100vh - 150px);
}

.MuiDrawer-modal .image-gallery-slides{
  height: calc(100vh - 300px);
}
  .MuiPickersPopper-root{
    z-index: 1900!important;
  }
  .MuiPickersDateRangePickerInput-root{
    display: inherit!important;
    flex-direction: inherit!important;
  }
  .shake {
    /* animation-name: shake;
    animation-duration: 1s 1s;
    animation-iteration-count: infinite; */

    animation: shake 1500ms cubic-bezier(0.84, 0.51, 0.89, 0.68) 1500ms infinite;
  }
  .rotate {
    transform: rotateZ(45deg);
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 0px) rotate(0deg); }
    3% { transform: translate(-1px, 0px) rotate(-1deg); }
    6% { transform: translate(-3px, 0px) rotate(1deg); }
    9% { transform: translate(3px, 0px) rotate(0deg); }
    12% { transform: translate(1px, 0px) rotate(1deg); }
    15% { transform: translate(-1px, 0px) rotate(-1deg); }
    18% { transform: translate(-3px, 0px) rotate(0deg); }
    21% { transform: translate(3px, 0px) rotate(-1deg); }
    24% { transform: translate(1px, 0px) rotate(1deg); }
    27% { transform: translate(-1px, 0px) rotate(0deg); }
    30% { transform: translate(-3px, 0px) rotate(0deg); }
    33% { transform: translate(0) rotate(0deg); }
  }
  

.leaflet-container {
	width: 100wh; 
  height: 100vh;
  z-index: 0;
  }
  
  
.overline{
  font-size: 9.5px!important;
  margin-top: -5px!important;
  text-indent: 6.5px;
}

.line-through {
    text-decoration: line-through;
}
    
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto";
  margin: 0;
}
.main-paper {
  height: 100vh;
  overflow: auto;
}

.inputs-range-container{
  display: flex;
  font-size: 10px;
  width: 152px;
  align-items: center;
}

.inputs-range-container-delimiter {
  padding: 0 5px;
}

.rotate-180g{
  transform: rotate(180deg);
}

input {
  font-size: .85rem!important;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.max-height {height: 100%}
.no-overflow {overflow: hidden;}
.auto-overflow {overflow: auto;}


.no-padding{
  padding: 0!important;
  margin: 0!important;
}
