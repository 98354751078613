  .wave {
    width: 100%;
    height: 400px;
    left: 0;
    top: 0;
    position: absolute;
    overflow: hidden;
    /* opacity: .7; */
    border-radius: 2px;
    /* box-shadow: inset 0px 0px 16px 2px #d1d1d1; */
  }
  
  .wave::after,
  .wave::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100px;
    
    background: rgba(140, 140, 140, .1);
    box-shadow: 0px 0px 16px rgba(100, 100, 100, .3);
  }
  
  .wave::before {
    width: 600px;
    height: 600px;
    bottom: 70px;
    left: -70px;
  
    border-radius: 45%;
    animation: spin 6.5s infinite linear;
  }
  
  .wave::after {
    width: 700px;
    height: 700px;
    left: 0px;
    left: -175px;
  
    border-radius: 40%;
    animation: spin 6s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  